@import url(https://fonts.googleapis.com/css?family=Roboto:300,300italic,400,400italic,500,700,700italic);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,300italic,400,400italic,500,700,700italic);
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
figure,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    border: 0;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline
}

body {
    background: #fff;
    color: #333;
    line-height: 1
}

header,
footer,
section,
article,
nav,
aside,
main {
    display: block
}

*,
*:before,
*:after {
    box-sizing: border-box
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal
}

a,
a:hover {
    color: inherit;
    text-decoration: none
}

a:focus,
:focus {
    outline: none
}

ol {
    list-style: decimal;
    margin: 0 0 0 2em
}

ol ol {
    list-style: upper-alpha
}

ol ol ol {
    list-style: upper-roman
}

ol ol ol ol {
    list-style: lower-alpha
}

ol ol ol ol ol {
    list-style: lower-roman
}

ul {
    list-style: disc;
    margin: 0 0 0 2em
}

ul ul {
    list-style: circle
}

ul ul ul {
    list-style: square
}

input,
textarea,
button {
    font-family: inherit;
    font-size: inherit
}

textarea {
    resize: none
}

input[type="checkbox"] {
    vertical-align: bottom;
    *vertical-align: baseline
}

button {
    cursor: pointer
}

input[type="radio"] {
    vertical-align: text-bottom
}

input {
    _vertical-align: text-bottom
}

textarea {
    display: block
}

table {
    border-collapse: separate;
    border-spacing: 0
}

caption,
th,
td {
    font-weight: normal;
    text-align: left
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: ""
}

blockquote,
q {
    quotes: """"
}

@-webkit-keyframes 'loader-spin' {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes 'loader-spin' {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.form__fields,
.grid,
.location__features,
.result-list,
.timeslots {
    list-style: none;
    margin: 0
}

.btn,
#agr_wrap button.agr_btn,
#agr_wrap a.agr_btn,
.btn--outline,
.btn--link,
.btn--remove,
.date-select__btn,
.toast__close {
    background: none;
    border: none;
    display: inline-block;
    margin: 0;
    padding: 0
}

.loader--async:empty:before,
.select:after {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.react-datepicker__navigation:before {
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%)
}

.dialog,
.loader,
a,
.btn--link,
a .icon,
.btn--link .icon,
.btn,
#agr_wrap button.agr_btn,
#agr_wrap a.agr_btn,
.btn--outline,
.btn:before,
#agr_wrap button.agr_btn:before,
#agr_wrap a.agr_btn:before,
.btn--outline:before,
.btn--remove,
.btn--remove .icon,
.toast__close .icon,
.tooltip__body {
    -webkit-transition: all .25s ease;
    transition: all .25s ease
}

.meta {
    border: 0 !important;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important
}

body,
#agr_wrap h1.agr_title {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.select:after {
    border: 4px solid #444;
    border-bottom-width: 0;
    border-right-color: transparent;
    border-left-color: transparent;
    content: '';
    display: inline-block;
    height: 0;
    margin-left: 4px;
    vertical-align: middle;
    width: 0
}

.dialog,
.loader {
    bottom: 0;
    left: 0;
    padding: 15px;
    position: fixed;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 3000
}

.dialog:before,
.loader:before {
    content: '';
    display: inline-block;
    height: 100%;
    margin-right: -10px;
    vertical-align: middle;
    width: 10px
}

.dialog__content,
.loader__msg {
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15);
    display: inline-block;
    vertical-align: middle
}

body {
    color: #444;
    font-family: "Roboto", sans-serif
}

.content {
    position: relative
}

.wrap {
    margin: 0 auto;
    max-width: 78.75rem;
    padding: 0 15px
}

@media only screen and (min-width: 48em) {
    .wrap {
        padding: 0 30px
    }
}

a,
.btn--link {
    color: #083dae;
    text-decoration: underline
}

a:hover,
.btn--link:hover {
    color: #041b4c;
    text-decoration: underline
}

a:hover .icon,
.btn--link:hover .icon {
    fill: #041b4c
}

a:focus:not([class*='btn']):not(.navbar__item),
.btn--link:focus:not([class*='btn']):not(.navbar__item) {
    color: #041b4c;
    text-decoration: underline
}

h1,
#agr_wrap h1.agr_title {
    font-size: 1.5rem
}

h2,
.box__title {
    font-size: 1.25rem
}

h3 {
    font-size: 1.1875rem
}

h4,
.box__subheading {
    font-size: 1.125rem
}

h5,
.callout__title,
.dialog__title,
.location__title {
    font-size: 1rem
}

h6,
.location--compact .location__title,
.location__price,
.media__title {
    font-size: 1rem
}

p,
ol,
ul,
dl,
.preposition,
.back,
.btn,
#agr_wrap button.agr_btn,
#agr_wrap a.agr_btn,
.btn--outline,
.dialog__content p,
.form__lbl,
input[type="color"],
input[type="date"],
input[type="datetime-local"],
input[type="datetime"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
textarea,
.select select,
.timeslots__date,
.toast__content,
.tooltip__body {
    font-size: .875rem
}

@media only screen and (min-width: 48em) {

    h1,
    #agr_wrap h1.agr_title {
        font-size: 2rem
    }

    h2,
    .box__title {
        font-size: 1.75rem
    }

    h3 {
        font-size: 1.5rem
    }

    h4,
    .box__subheading {
        font-size: 1.25rem
    }

    h5,
    .callout__title,
    .dialog__title,
    .location__title {
        font-size: 1.125rem
    }

    h6,
    .location--compact .location__title,
    .location__price,
    .media__title {
        font-size: 1rem
    }

    p,
    ol,
    ul,
    dl,
    .preposition,
    .back,
    .btn,
    #agr_wrap button.agr_btn,
    #agr_wrap a.agr_btn,
    .btn--outline,
    .dialog__content p,
    .form__lbl,
    input[type="color"],
    input[type="date"],
    input[type="datetime-local"],
    input[type="datetime"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    textarea,
    .select select,
    .timeslots__date,
    .toast__content,
    .tooltip__body {
        font-size: .875rem
    }
}

h1 {
    line-height: 1.4
}

h2 {
    line-height: 1.6785
}

h3 {
    line-height: 1.666666667
}

h4 {
    line-height: 1.7
}

h5 {
    line-height: 1.666666667
}

h6 {
    line-height: 1.6875
}

p,
ol,
ul,
dl {
    line-height: 1.6875;
    margin-bottom: 1.6875em
}

em {
    font-style: italic
}

strong {
    font-weight: 700
}

abbr {
    text-decoration: none
}

.preposition {
    display: inline-block;
    margin: 0 .625rem
}

.preposition--vertical {
    margin: .75rem 0 .625rem
}

.no-results {
    display: block;
    line-height: 1.2;
    margin: 1em 0;
    text-align: center
}

.no-results:last-child {
    margin-bottom: 0
}

.adr {
    margin-bottom: 0
}

.adr__street {
    display: block
}

* + .adr__region:before,
* + .adr__postal:before {
    content: ' '
}

#agr_wrap h1.agr_title {
    color: #444;
    font-weight: 400;
    margin-bottom: .625rem;
    text-align: center
}

#agr_wrap button.agr_btn:before,
#agr_wrap button.agr_btn:after,
#agr_wrap a.agr_btn:before,
#agr_wrap a.agr_btn:after {
    border-radius: 0;
    position: static;
    -webkit-transform: scaleX(0) translateX(0);
    transform: scaleX(0) translateX(0)
}

#agr_wrap button.agr_btn:hover:before,
#agr_wrap a.agr_btn:hover:before {
    width: 100%
}

.animation--fade {
    position: relative
}

.animation--fade-enter {
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    z-index: 2
}

.animation--fade-enter.animation--fade-enter-active {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    -webkit-transition: all .25s ease;
    transition: all .25s ease
}

.animation--fade-exit {
    position: absolute;
    top: 0;
    min-width: 100%
}

.animation--fade-exit.animation--fade-exit-active {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    -webkit-transition: all .25s ease;
    transition: all .25s ease
}

.back {
    text-decoration: none
}

.back:before {
    content: '';
    border: 3px solid #083dae;
    border-width: 3px 0 0 3px;
    display: inline-block;
    height: .5rem;
    margin-right: .5ex;
    position: relative;
    top: -1px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: .5rem
}

.back:hover {
    text-decoration: none
}

.back:hover:before {
    left: -1px
}

.block {
    border: 1px solid #ddd;
    border-radius: 3px;
    overflow: hidden;
    text-align: left
}

.box {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
    padding: 15px;
    position: relative
}

@media only screen and (min-width: 48em) {
    .box {
        padding: 30px
    }
}

.box p:last-child,
.box ol:last-child,
.box ul:last-child {
    margin-bottom: 0
}

.box--md {
    max-width: 41.375rem;
    width: 100%
}

.box--sm {
    max-width: 30.125rem;
    width: 100%
}

.box--solo {
    margin: 0 auto;
    text-align: center
}

.box__content,
.box--form {
    text-align: left
}

.box__title {
    margin-bottom: .75rem;
    text-align: center
}

.box__title--alt {
    text-align: left
}

.box__subheading {
    display: block;
    font-weight: 700;
    margin-bottom: 1.25rem
}

.box__content {
    margin-bottom: 1.25rem
}

.box__back {
    background: #f7f7f7;
    border-radius: 3px 3px 0 0;
    border-bottom: 1px solid #ddd;
    display: block;
    margin: -15px -15px 15px;
    padding: .625rem 15px;
    text-align: left
}

@media only screen and (min-width: 48em) {
    .box__back {
        margin: -30px -30px 15px
    }
}

.box__section {
    margin: 15px 0 30px
}

.box__section:last-child {
    margin-bottom: 0
}

.box__action {
    background: #f7f7f7;
    border-top: 1px solid #ddd;
    border-radius: 0 0 3px 3px;
    display: flex;
    margin: 15px -15px -15px;
    padding: 15px
}

.box__action > .btn {
    margin-left: .625rem
}

.box__action > .btn:first-child {
    margin-left: auto
}

@media only screen and (min-width: 48em) {
    .box__action {
        margin: 30px -30px -30px
    }
}


.btn,
#agr_wrap button.agr_btn,
#agr_wrap a.agr_btn,
.btn--outline {
    background: #083dae;
    border: none;
    border-radius: 3px;
    color: #fff;
    display: inline-block;
    line-height: 1.5;
    margin: 0;
    overflow: hidden;
    padding: 8px 15px 6px;
    position: relative;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    z-index: 1
}

.btn:before,
#agr_wrap button.agr_btn:before,
#agr_wrap a.agr_btn:before,
.btn--outline:before {
    background: #0a4edf;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    width: 100%;
    z-index: -1
}

.btn:hover,
#agr_wrap button.agr_btn:hover,
#agr_wrap a.agr_btn:hover,
.btn--outline:hover {
    color: #fff;
    text-decoration: none
}

.btn:hover:before,
#agr_wrap button.agr_btn:hover:before,
#agr_wrap a.agr_btn:hover:before,
.btn--outline:hover:before {
    opacity: 1;
    -webkit-transform: none;
    transform: none
}

.btn:focus:not(:active):not(.btn--clicked),
#agr_wrap button.agr_btn:focus:not(:active):not(.btn--clicked),
#agr_wrap a.agr_btn:focus:not(:active):not(.btn--clicked),
.btn--outline:focus:not(:active):not(.btn--clicked) {
    outline: 2px dotted #000
}

.btn--outline {
    background: #fff;
    border: 1px solid #083dae;
    color: #083dae;
    padding: 7px 14px 5px
}

.btn--outline:hover {
    border-color: #2769f5;
    color: #fff
}

.btn--negative {
    background: #db4359;
    color: #fff
}

.btn--negative:before {
    background: #e36e7f
}

.btn--negative.btn--outline {
    background: #fff;
    border-color: #db4359;
    color: #db4359
}

.btn--negative.btn--outline:before {
    background: #db4359
}

.btn--negative.btn--outline:hover {
    color: #fff
}

.btn--remove {
    height: 1.875rem;
    width: 1.875rem
}

.btn--remove .icon {
    fill: #444;
    height: .75rem;
    width: .75rem
}

.btn--remove:hover .icon {
    fill: #db4359
}

.callout {
    background: #f7f7f7;
    border: 1px solid #ddd;
    margin-top: 15px;
    padding: 15px
}

.callout:first-child {
    margin-top: 0
}

.callout p:last-of-type {
    margin-bottom: 15px
}

@media only screen and (min-width: 48em) {
    .callout {
        margin-top: 30px
    }
}

.callout__title {
    margin-bottom: .625rem
}

.callout__btn {
    background: none
}

.date-select {
    display: inline-block;
    /* margin-right: .3125rem; */
    margin-bottom: .3125rem;
    position: relative;
    vertical-align: middle
}

.date-select > * + *,
.date-select > div > * + * {
    margin-left: .3125rem
}

.date-select .meta + * {
    margin-left: 0
}

.date-select .field-error {
    bottom: -1.5625rem;
    left: 0;
    position: absolute;
    white-space: nowrap
}

.date-select__btn {
    height: 2.1875rem;
    vertical-align: middle
}

.date-select__btn .icon {
    height: 1.25rem;
    fill: #083dae;
    width: 1.25rem
}

.date-select__picker {
    bottom: 0;
    left: 0;
    margin: 0;
    position: absolute
}

.date-select__picker input {
    display: none !important
}

.date-select__picker--inline {
    position: static
}

.date-select__picker--inline .react-datepicker {
    box-shadow: none;
    max-width: 27.5rem;
    width: auto;
    display: flex
}

.date-select__picker--inline .react-datepicker__month-container {
    max-width: 13.75rem;
    width: 100%
}

.date-select__picker--inline .react-datepicker__month-container + .react-datepicker__month-container {
    display: none
}

@media only screen and (min-width: 48em) {
    .date-select__picker--inline .react-datepicker__month-container {
        width: 50%
    }

    .date-select__picker--inline .react-datepicker__month-container + .react-datepicker__month-container {
        display: block
    }

    .date-select__picker--inline .react-datepicker__month-container:last-child {
        border-left: 1px solid #ddd
    }
}

.date-select__clear {
    padding: .3125rem
}

.date-select__clear .icn {
    fill: #db4359
}

.react-datepicker__input-container {
    display: inline-block;
    width: 100%;
}

div.react-datepicker__dual .react-datepicker__input-container {
    display: inline-block;
    width: calc(50% - 10px)
}

div.react-datepicker__dual .preposition {
    width: 1rem
}

.react-datepicker__tether-element {
    z-index: 3000
}

.react-datepicker {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.05);
    font-weight: 300;
    width: 220px
}

.react-datepicker__navigation {
    background: none;
    border-bottom: 6px solid transparent;
    border-top: 6px solid transparent;
    cursor: pointer;
    display: inline-block;
    height: 0;
    overflow: hidden;
    line-height: normal;
    padding: 0;
    position: absolute;
    text-indent: -999em;
    top: .75rem;
    width: 0
}

.react-datepicker__navigation:before {
    content: '';
    height: 30px;
    position: absolute;
    width: 30px
}

.react-datepicker__navigation--previous {
    border-right: 8px solid #20394f;
    border-left: none;
    left: 20px
}

.react-datepicker__navigation--previous:hover {
    border-right-color: #444
}

.react-datepicker__navigation--next {
    border-left: 8px solid #20394f;
    border-right: none;
    right: 20px
}

.react-datepicker__navigation--next:hover {
    border-left-color: #444
}

.react-datepicker__header {
    background: #f7f7f7;
    border-bottom: 1px solid #ddd;
    padding: 10px 10px 2px
}

.react-datepicker__current-month {
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center
}

.react-datepicker__month,
.react-datepicker__day-names {
    display: table;
    table-layout: fixed;
    width: 100%
}

.react-datepicker__month {
    padding: 10px
}

.react-datepicker__week {
    display: table-row
}

.react-datepicker__day,
.react-datepicker__day-name {
    display: table-cell;
    padding: 5px;
    text-align: center
}

.react-datepicker__day {
    border-radius: 3px;
    cursor: pointer
}

.react-datepicker__day:hover,
.react-datepicker__day.react-datepicker__day--keyboard-selected {
    background: #aaa
}

.react-datepicker__day--today {
    color: #083dae
}

.react-datepicker__day--selected {
    background: #083dae;
    color: #fff
}

.react-datepicker__day--disabled,
.react-datepicker__day--outside-month {
    color: #aaa
}

.react-datepicker__day--disabled:hover,
.react-datepicker__day--outside-month:hover {
    background: #f7f7f7
}

.react-datepicker__day--disabled.react-datepicker__day--today,
.react-datepicker__day--disabled.react-datepicker__day--selected,
.react-datepicker__day--outside-month.react-datepicker__day--today,
.react-datepicker__day--outside-month.react-datepicker__day--selected {
    background: none
}

.dialog__content {
    max-height: 95%;
    max-width: 95%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    padding: 20px;
    text-align: left;
    width: 28.125rem
}

.dialog__content::-webkit-scrollbar {
    width: 0
}

.dialog__content label {
    display: block;
    margin-bottom: 5px
}

.dialog__content p {
    line-height: 1.5;
    margin-bottom: 1em
}

.dialog__title {
    background: #083dae;
    color: #fff;
    display: block;
    font-weight: 400;
    margin: -20px -20px .9375rem;
    padding: 19px 21px 17px
}

.dialog__action {
    text-align: right
}

.dialog__action * + * {
    margin-left: .625rem
}

.select + .dialog__action {
    margin-top: 1.25rem
}

.form {
    text-align: left
}

.form--inline {
    display: flex;
    align-items: flex-start
}

.form--inline fieldset {
    width: 100%
}

.form--inline .btn {
    flex-shrink: 0;
    margin: 1.75rem 0 0 15px
}

.form__section {
    border-bottom: 1px solid #ddd;
    padding: 15px
}

@media only screen and (min-width: 48em) {
    .form__section {
        padding: 1.1875rem 30px 1.25rem
    }
}

.form__heading {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    float: left;
    font-weight: 700;
    margin-bottom: .75rem;
    position: relative;
    width: 100%
}

.form__heading + * {
    clear: both
}

.collapsed .form__heading {
    margin-bottom: -.125rem
}

.form__fields {
    margin-bottom: 15px;
    overflow: hidden;
    text-align: left
}

.form__fields > li {
    float: left;
    width: 100%
}

.form__fields > li + li {
    margin-top: 15px
}

.form__fields > li > label:not(.form__err) {
    display: table
}

.form__fields > li.field--half {
    padding-right: 7px;
    width: 50%
}

.form__fields > li.field--half + .field--half {
    margin-top: 0;
    padding: 0 0 0 8px
}

.form__fields > li.field--multi * + * + * {
    margin-top: .3125rem
}

@media only screen and (max-width: 47.9375em) {

    .form__fields > li.field--multi input,
    .form__fields > li.field--multi .select,
    .form__fields > li.field--multi .select select {
        width: 100%
    }
}

@media only screen and (min-width: 48em) {
    .form__fields > li.field--multi {
        display: flex;
        flex-flow: row wrap
    }

    .form__fields > li.field--multi label:not(.select) {
        flex-basis: 100%;
        width: 100%
    }

    .form__fields > li.field--multi * + * + *:not(.form__err) {
        margin-top: 0;
        margin-left: 15px
    }

    .form__fields > li.field--multi input {
        flex: 1 1;
        width: auto
    }
}

.form__fields--justify {
    display: table;
    width: 100%
}

.form__fields--justify .dropdown {
    max-width: 16.625rem
}

.form__fields--justify > li {
    display: table-row;
    float: none
}

.form__fields--justify > li > label {
    white-space: nowrap
}

.form__fields--justify > li > div {
    width: 100%
}

.form__fields--justify > li + li > * {
    border-top: .9375rem solid transparent
}

.form__fields--justify > li > * {
    display: table-cell
}

.form__fields--justify > li > * + * {
    padding-left: .625rem
}

.form__fields--justify > li > * select {
    width: 100%
}

.form__lbl {
    display: table;
    margin-bottom: .3125rem
}

.form--submitted input[required]:invalid,
.form--submitted textarea[required]:invalid {
    border-color: #db4359
}

.form__err {
    background: #f5ccd2;
    color: #db4359;
    display: block;
    font-weight: 700;
    margin-top: .3125rem;
    padding: .3125rem 15px
}

.grid {
    display: flex;
    align-content: center;
    flex-flow: row wrap;
    justify-content: space-between
}

.grid > * {
    width: 25%
}

.grid > *:nth-last-child(2):first-child,
.grid > *:nth-last-child(2):first-child ~ * {
    width: 50%
}

.grid > *:nth-last-child(3):first-child,
.grid > *:nth-last-child(3):first-child ~ * {
    width: 33.333333333%
}

.grid--separated > * {
    border: 1px solid #ddd;
    border-width: 0 1px;
    padding: 15px;
    position: relative
}

.grid--separated > *:first-child {
    border-left: none
}

.grid--separated > * + * {
    left: -1px
}

.grid--separated > *:nth-child(4n) {
    border-right: none
}

.grid--separated > *:nth-last-child(2):first-child:nth-child(even),
.grid--separated > *:nth-last-child(2):first-child ~ *:nth-child(even) {
    border-right: none
}

.grid--separated > *:nth-last-child(3):first-child:nth-child(3n),
.grid--separated > *:nth-last-child(3):first-child ~ *:nth-child(3n) {
    border-right: none
}

.site-head {
    background: #f7f7f7;
    border-bottom: 1px solid #ddd;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
    margin-bottom: 15px;
    padding: 15px 15px 12px
}

@media only screen and (min-width: 48em) {
    .site-head {
        margin-bottom: 30px
    }
}

input[type="color"],
input[type="date"],
input[type="datetime-local"],
input[type="datetime"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
textarea,
.payment__fields {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 3px
}

.faux-input,
input[type="color"]:disabled,
input[type="date"]:disabled,
input[type="datetime-local"]:disabled,
input[type="datetime"]:disabled,
input[type="email"]:disabled,
input[type="month"]:disabled,
input[type="number"]:disabled,
input[type="password"]:disabled,
input[type="search"]:disabled,
input[type="tel"]:disabled,
input[type="text"]:disabled,
input[type="time"]:disabled,
input[type="url"]:disabled,
input[type="week"]:disabled,
textarea:disabled {
    background: #f7f7f7;
    color: #20394f;
    display: inline-block;
    line-height: 2.1875rem
}

input[type="color"],
input[type="date"],
input[type="datetime-local"],
input[type="datetime"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 2.1875rem;
    color: #20394f;
    padding-left: 10px;
    -webkit-transition: border-color .25s ease;
    transition: border-color .25s ease;
    width: 100%
}

input[type="color"]:focus,
input[type="date"]:focus,
input[type="datetime-local"]:focus,
input[type="datetime"]:focus,
input[type="email"]:focus,
input[type="month"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="text"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
input[type="week"]:focus,
textarea:focus {
    border-color: #083dae
}

input[type="color"]::-webkit-input-placeholder,
input[type="date"]::-webkit-input-placeholder,
input[type="datetime-local"]::-webkit-input-placeholder,
input[type="datetime"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="month"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="search"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
input[type="text"]::-webkit-input-placeholder,
input[type="time"]::-webkit-input-placeholder,
input[type="url"]::-webkit-input-placeholder,
input[type="week"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #aaa
}

input[type="color"]:-ms-input-placeholder,
input[type="date"]:-ms-input-placeholder,
input[type="datetime-local"]:-ms-input-placeholder,
input[type="datetime"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="month"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="search"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="text"]:-ms-input-placeholder,
input[type="time"]:-ms-input-placeholder,
input[type="url"]:-ms-input-placeholder,
input[type="week"]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #aaa
}

input[type="color"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="datetime-local"]::-webkit-input-placeholder, input[type="datetime"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="month"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder, input[type="search"]::-webkit-input-placeholder, input[type="tel"]::-webkit-input-placeholder, input[type="text"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="url"]::-webkit-input-placeholder, input[type="week"]::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: #aaa
}

input[type="color"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="datetime-local"]:-ms-input-placeholder, input[type="datetime"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="month"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder, input[type="search"]:-ms-input-placeholder, input[type="tel"]:-ms-input-placeholder, input[type="text"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="url"]:-ms-input-placeholder, input[type="week"]:-ms-input-placeholder, textarea:-ms-input-placeholder {
    color: #aaa
}

input[type="color"]::-ms-input-placeholder, input[type="date"]::-ms-input-placeholder, input[type="datetime-local"]::-ms-input-placeholder, input[type="datetime"]::-ms-input-placeholder, input[type="email"]::-ms-input-placeholder, input[type="month"]::-ms-input-placeholder, input[type="number"]::-ms-input-placeholder, input[type="password"]::-ms-input-placeholder, input[type="search"]::-ms-input-placeholder, input[type="tel"]::-ms-input-placeholder, input[type="text"]::-ms-input-placeholder, input[type="time"]::-ms-input-placeholder, input[type="url"]::-ms-input-placeholder, input[type="week"]::-ms-input-placeholder, textarea::-ms-input-placeholder {
    color: #aaa
}

input[type="color"]::placeholder,
input[type="date"]::placeholder,
input[type="datetime-local"]::placeholder,
input[type="datetime"]::placeholder,
input[type="email"]::placeholder,
input[type="month"]::placeholder,
input[type="number"]::placeholder,
input[type="password"]::placeholder,
input[type="search"]::placeholder,
input[type="tel"]::placeholder,
input[type="text"]::placeholder,
input[type="time"]::placeholder,
input[type="url"]::placeholder,
input[type="week"]::placeholder,
textarea::placeholder {
    color: #aaa
}

.touch input[type="color"],
.touch input[type="date"],
.touch input[type="datetime-local"],
.touch input[type="datetime"],
.touch input[type="email"],
.touch input[type="month"],
.touch input[type="number"],
.touch input[type="password"],
.touch input[type="search"],
.touch input[type="tel"],
.touch input[type="text"],
.touch input[type="time"],
.touch input[type="url"],
.touch input[type="week"],
.touch textarea {
    font-size: 1rem
}

input[type="color"]:disabled,
input[type="date"]:disabled,
input[type="datetime-local"]:disabled,
input[type="datetime"]:disabled,
input[type="email"]:disabled,
input[type="month"]:disabled,
input[type="number"]:disabled,
input[type="password"]:disabled,
input[type="search"]:disabled,
input[type="tel"]:disabled,
input[type="text"]:disabled,
input[type="time"]:disabled,
input[type="url"]:disabled,
input[type="week"]:disabled,
textarea:disabled {
    line-height: normal
}

textarea {
    height: 6.25rem;
    padding: 5px 10px;
    resize: vertical
}

@-webkit-keyframes loaderFade {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes loaderFade {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.loader {
    -webkit-animation: .25s loaderFade ease;
    animation: .25s loaderFade ease
}

.loader.leave {
    -webkit-animation: .25s loaderFade ease reverse forwards;
    animation: .25s loaderFade ease reverse forwards
}

@-webkit-keyframes loader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    15% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    85% {
        -webkit-transform: rotate(720deg);
        transform: rotate(720deg)
    }

    100% {
        -webkit-transform: rotate(720deg);
        transform: rotate(720deg)
    }
}

@keyframes loader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    15% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    85% {
        -webkit-transform: rotate(720deg);
        transform: rotate(720deg)
    }

    100% {
        -webkit-transform: rotate(720deg);
        transform: rotate(720deg)
    }
}

.loader__msg {
    color: #20394f;
    font-weight: 500;
    overflow: hidden;
    padding: 15px
}

.loader__msg:before {
    -webkit-animation: 2s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite loader;
    animation: 2s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite loader;
    border: 10px solid #083dae;
    border-right-color: #083dae;
    border-bottom-color: #db4359;
    border-left-color: #db4359;
    border-radius: 50%;
    content: '';
    display: block;
    margin: 0 auto;
    height: 40px;
    width: 40px
}

@-webkit-keyframes loaderMask {
    from {
        background-position: -1000px 0
    }

    to {
        background-position: 1000px 0
    }
}

@keyframes loaderMask {
    from {
        background-position: -1000px 0
    }

    to {
        background-position: 1000px 0
    }
}

.loader--async:empty {
    height: 100px
}

.loader--async:empty:before {
    -webkit-animation: 2s linear infinite loaderMask;
    animation: 2s linear infinite loaderMask;
    background: -webkit-gradient(linear, right top, left top, color-stop(10%, #f7f7f7), color-stop(20%, #ddd), color-stop(40%, #f7f7f7)) #f7f7f7 no-repeat;
    background: linear-gradient(to left, #f7f7f7 10%, #ddd 20%, #f7f7f7 40%) #f7f7f7 no-repeat;
    border-radius: 3px;
    content: '';
    display: block;
    height: 20px;
    margin: 20px auto;
    max-width: 300px;
    overflow: hidden;
    position: relative;
    text-indent: -999em;
    width: 100%
}

.location {
    text-align: left
}

@media only screen and (min-width: 48em) {
    .location {
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between
    }
}

.location__section {
    position: relative
}

.location__map {
    margin: 0 15px .3125rem 0;
    max-width: 100px;
    overflow: hidden
}

.location__map img {
    display: block;
    margin-left: -25px
}

.location__map:focus:not(:active) {
    outline: 2px dotted #000
}

@media only screen and (min-width: 48em) {
    .location__map {
        margin: 0;
        max-width: none
    }

    .location__map img {
        margin-left: 0
    }
}

.location__adr.media {
    display: flex;
    text-align: left
}

@media only screen and (min-width: 48em) {
    .location__adr.media {
        flex-shrink: 1;
        width: calc(100% - 9.375rem)
    }
}

.location__title {
    font-weight: 700
}

.location__parking {
    display: block
}

.location__action {
    background: #f7f7f7;
    border: 1px solid #ddd;
    padding: 15px;
    margin: .625rem 0 0;
    text-align: center
}

@media only screen and (min-width: 48em) {
    .location__action {
        background: none;
        border: none;
        flex-shrink: 0;
        margin: -.625rem 0 0 15px;
        padding: 0
    }
}

.location__price {
    display: block;
    margin-bottom: .3125rem;
    text-align: center
}

.location__detail {
    flex-basis: 100%;
    margin-top: .9375rem
}

.location__features {
    -webkit-columns: 2;
    columns: 2;
    line-height: 2
}

.location__features .icn {
    fill: #aaa;
    margin-right: .625rem;
    vertical-align: middle
}

@media only screen and (min-width: 48em) {
    .location__features {
        -webkit-columns: 3;
        columns: 3
    }
}

.location__features-all {
    background: #fff;
    line-height: 1.6875;
    position: absolute;
    right: 0;
    top: 0
}

.location__features-all:before {
    content: '';
    background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), color-stop(80%, #fff));
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 80%);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    width: 3.125rem
}

.media {
    text-align: center
}

@media only screen and (min-width: 48em) {
    .media {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left
    }
}

.media__title {
    font-weight: 700
}

.media__caption {
    margin: .3125rem 0 0;
    order: 1;
    width: 100%
}

.media--alt .media__caption {
    order: 0
}

@media only screen and (min-width: 48em) {
    .media__caption {
        margin: 0 0 0 15px
    }
}

.media__content {
    flex-shrink: 0;
    order: 0
}

.media--alt .media__content {
    order: 1
}

.page {
    margin-bottom: 15px
}

@media only screen and (min-width: 48em) {
    .page {
        margin-bottom: 30px
    }
}

.page--solo {
    margin-top: 3.75rem
}

.payment__fields {
    padding-left: 10px
}

.payment-submitted {
    opacity: .25
}

.result-list {
    border-bottom: 1px solid #ddd;
    margin-top: 15px
}

.result-list > li {
    border-top: 1px solid #ddd;
    padding: 15px 0
}

.select select {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    color: #444;
    height: 100%;
    line-height: 1.5;
    padding: 6px 37px 6px 10px
}

.select select:focus {
    border-color: #083dae
}

.select select:disabled {
    background: #f7f7f7;
    color: #20394f
}

.select select::-ms-expand {
    display: none
}

.select {
    display: inline-block;
    line-height: 1.5;
    position: relative
}

.select:before {
    background: -webkit-gradient(linear, right top, left top, from(#fff), to(rgba(255, 255, 255, 0)));
    background: linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
    border-radius: 0 3px 3px 0;
    bottom: 1px;
    content: '';
    pointer-events: none;
    position: absolute;
    right: 27px;
    top: 1px;
    width: 1.25rem
}

.select:after {
    position: absolute;
    right: 10px
}

.select select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%
}

.select select:invalid {
    color: #aaa
}

.select__lbl {
    margin-right: .625rem
}

.timeslots {
    border-bottom: 1px solid #ddd;
    text-align: left
}

.timeslots > li {
    align-items: center;
    border-top: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    padding: 15px 0
}

.timeslots__date {
    display: block
}

@-webkit-keyframes toasterSlide {
    from {
        opacity: 0;
        -webkit-transform: translateX(-150%);
        transform: translateX(-150%)
    }

    to {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes toasterSlide {
    from {
        opacity: 0;
        -webkit-transform: translateX(-150%);
        transform: translateX(-150%)
    }

    to {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@-webkit-keyframes toasterFade {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes toasterFade {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

.toast {
    -webkit-animation: toasterSlide .35s ease;
    animation: toasterSlide .35s ease;
    background: #fff;
    bottom: 15px;
    border-radius: 3px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
    left: 15px;
    padding: 15px 2.8125rem 13px 15px;
    position: fixed;
    width: 18.75rem
}

.toast--exiting {
    -webkit-animation: toasterFade .25s ease forwards;
    animation: toasterFade .25s ease forwards
}

.toast__content {
    line-height: 1.5
}

.toast__close {
    height: 1.875rem;
    position: absolute;
    right: .5rem;
    top: .6875rem;
    width: 1.875rem
}

.toast__close .icon {
    fill: #aaa;
    height: 1rem;
    width: 1rem
}

.toast__close:hover .icon {
    fill: #db4359
}

.tooltip {
    display: inline-block;
    position: relative
}

.tooltip:hover .tooltip__body {
    opacity: 1;
    pointer-events: default;
    visibility: visible
}

.tooltip__label {
    color: #083dae;
    cursor: pointer;
    margin: 0 .5ex 0;
    position: relative;
    top: -.0625rem
}

.tooltip__body {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.05);
    color: #444;
    cursor: default;
    left: 50%;
    line-height: 1.5;
    margin: 0;
    opacity: 0;
    padding: 10px 15px 8px;
    pointer-events: none;
    position: absolute;
    top: -5px;
    -webkit-transform: translateY(-100%) translateX(-50%);
    transform: translateY(-100%) translateX(-50%);
    white-space: normal;
    width: 15.625rem;
    z-index: 3000
}

.tooltip__body:after {
    background: inherit;
    border: 1px solid #ddd;
    border-width: 1px 1px 0 0;
    bottom: -14px;
    content: '';
    height: 10px;
    left: 50%;
    margin-left: 3px;
    position: absolute;
    -webkit-transform: rotate(135deg) translateX(-50%);
    transform: rotate(135deg) translateX(-50%);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    width: 10px
}


/*Check toggle css*/ #agr_wrap div, #agr_wrap span, #agr_wrap applet, #agr_wrap object, #agr_wrap iframe, #agr_wrap h1, #agr_wrap h2, #agr_wrap h3, #agr_wrap h4, #agr_wrap h5, #agr_wrap h6, #agr_wrap p, #agr_wrap blockquote, #agr_wrap pre, #agr_wrap a, #agr_wrap abbr, #agr_wrap acronym, #agr_wrap address, #agr_wrap big, #agr_wrap cite, #agr_wrap code, #agr_wrap del, #agr_wrap dfn, #agr_wrap em, #agr_wrap figure, #agr_wrap font, #agr_wrap img, #agr_wrap ins, #agr_wrap kbd, #agr_wrap q, #agr_wrap s, #agr_wrap samp, #agr_wrap small, #agr_wrap strike, #agr_wrap strong, #agr_wrap sub, #agr_wrap sup, #agr_wrap tt, #agr_wrap var, #agr_wrap dl, #agr_wrap dt, #agr_wrap dd, #agr_wrap ol, #agr_wrap ul, #agr_wrap li, #agr_wrap fieldset, #agr_wrap form, #agr_wrap label, #agr_wrap legend, #agr_wrap table, #agr_wrap caption, #agr_wrap tbody, #agr_wrap tfoot, #agr_wrap thead, #agr_wrap tr, #agr_wrap th, #agr_wrap td {
    border: 0px;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0px;
    outline: 0px;
    padding: 0px;
    vertical-align: baseline;
}

#agr_wrap {
    background: rgb(255, 255, 255);
    color: rgb(51, 51, 51);
    line-height: 1;
}

#agr_wrap header, #agr_wrap footer, #agr_wrap section, #agr_wrap article, #agr_wrap nav, #agr_wrap aside, #agr_wrap main {
    display: block;
}

#agr_wrap *, #agr_wrap ::before, #agr_wrap ::after {
    box-sizing: border-box;
}

#agr_wrap h1, #agr_wrap h2, #agr_wrap h3, #agr_wrap h4, #agr_wrap h5, #agr_wrap h6 {
    font-weight: normal;
}

#agr_wrap a, #agr_wrap a:hover {
    color: inherit;
    text-decoration: none;
}

#agr_wrap a:focus, #agr_wrap :focus {
    outline: none;
}

#agr_wrap ol {
    list-style: decimal;
    margin: 0px 0px 0px 2em;
}

#agr_wrap ol ol {
    list-style: upper-alpha;
}

#agr_wrap ol ol ol {
    list-style: upper-roman;
}

#agr_wrap ol ol ol ol {
    list-style: lower-alpha;
}

#agr_wrap ol ol ol ol ol {
    list-style: lower-roman;
}

#agr_wrap ul {
    list-style: disc;
    margin: 0px 0px 0px 2em;
}

#agr_wrap ul ul {
    list-style: circle;
}

#agr_wrap ul ul ul {
    list-style: square;
}

#agr_wrap input, #agr_wrap textarea, #agr_wrap button {
    font-family: inherit;
    font-size: inherit;
}

#agr_wrap textarea {
    resize: none;
}

#agr_wrap input[type="checkbox"] {
    vertical-align: bottom;
}

#agr_wrap button {
    cursor: pointer;
}

#agr_wrap input[type="radio"] {
    vertical-align: text-bottom;
}

#agr_wrap input {
}

#agr_wrap textarea {
    display: block;
}

#agr_wrap table {
    border-collapse: separate;
    border-spacing: 0px;
}

#agr_wrap caption, #agr_wrap th, #agr_wrap td {
    font-weight: normal;
    text-align: left;
}

#agr_wrap blockquote::before, #agr_wrap blockquote::after, #agr_wrap q::before, #agr_wrap q::after {
    content: "";
}

#agr_wrap blockquote, #agr_wrap q {
    quotes: "" "";
}

@-webkit-keyframes loader-spin {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

#agr_wrap .agr_fields {
    list-style: none;
    margin: 0px;
}

#agr_wrap .agr_btn--reset, #agr_wrap .agr_btn--link, #agr_wrap .agr_popup__close {
    background: none;
    border: none;
    display: inline-block;
    margin: 0px;
    padding: 0px;
}

#agr_wrap .agr_select::after {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

#agr_wrap .agr_btn::before, #agr_wrap .agr_btn::after, #agr_wrap .agr_btn--outline::before, #agr_wrap .agr_btn--outline::after {
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
}

.agr_popup-wrap::before {
    content: "";
    display: inline-block;
    height: 100%;
    margin-right: -10px;
    vertical-align: middle;
    width: 10px;
}

#agr_wrap a, #agr_wrap a .icn, #agr_wrap .agr_cbox label::before, #agr_wrap .agr_cbox label::after, #agr_wrap .agr_radio label:not([class])::before {
    -webkit-transition: all 0.25s ease 0s;
    transition: all 0.25s ease 0s;
}

.sid_meta {
    clip: rect(1px, 1px, 1px, 1px);
    border: 0px !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0px !important;
    position: absolute !important;
    width: 1px !important;
}

#agr_wrap .agr_btn, #agr_wrap .agr_btn--outline {
    -webkit-font-smoothing: subpixel-antialiased;
}

#agr_wrap {
    -webkit-font-smoothing: auto;
}

#agr_wrap .agr_select::after {
    border-width: 4px 4px 0px;
    border-style: solid;
    border-color: rgb(118, 131, 143) transparent;
    -webkit-border-image: none;
            border-image: none;
    -webkit-border-image: initial;
            border-image: initial;
    content: "";
    display: inline-block;
    height: 0px;
    margin-left: 4px;
    vertical-align: middle;
    width: 0px;
}

#agr_wrap {
    background: none;
    color: rgb(118, 131, 143);
    font-family: Roboto, sans-serif;
    font-size: 14px;
    text-align: left;
}

#agr_wrap.agr_popup-wrap {
    text-align: center;
}

#agr_wrap a {
    color: rgb(0, 116, 204);
    text-decoration: underline;
}

#agr_wrap a:hover {
    color: rgb(0, 58, 102);
    text-decoration: underline;
}

#agr_wrap a:focus:not([class*="btn"]):not(.navbar__item) {
    color: rgb(0, 58, 102);
    text-decoration: underline;
}

#agr_wrap h1 {
    font-size: 1.85714em;
}

#agr_wrap h2 {
    font-size: 1.57143em;
}

#agr_wrap h3 {
    font-size: 1.42857em;
}

#agr_wrap h4 {
    font-size: 1.14286em;
}

#agr_wrap h5, #agr_wrap p, #agr_wrap ol, #agr_wrap ul, #agr_wrap dl, #agr_wrap input[type="color"], #agr_wrap input[type="date"], #agr_wrap input[type="datetime-local"], #agr_wrap input[type="datetime"], #agr_wrap input[type="email"], #agr_wrap input[type="month"], #agr_wrap input[type="number"], #agr_wrap input[type="password"], #agr_wrap input[type="search"], #agr_wrap input[type="tel"], #agr_wrap input[type="text"], #agr_wrap input[type="time"], #agr_wrap input[type="url"], #agr_wrap input[type="week"], #agr_wrap textarea {
    font-size: 1em;
}

#agr_wrap h6 {
    font-size: 0.85714em;
}

#agr_wrap h1 {
    line-height: 1.375;
}

#agr_wrap h2 {
    line-height: 1.41667;
}

#agr_wrap h3 {
    line-height: 1.5;
}

#agr_wrap h4 {
    line-height: 1.5625;
}

#agr_wrap h5, #agr_wrap p, #agr_wrap ol, #agr_wrap ul, #agr_wrap dl {
    line-height: 1.5;
}

#agr_wrap h6 {
    line-height: 1.83333;
}

#agr_wrap h5, #agr_wrap p, #agr_wrap ol, #agr_wrap ul, #agr_wrap dl {
    margin-bottom: 1.5em;
}

#agr_wrap em {
    font-style: italic;
}

#agr_wrap strong, #agr_wrap b {
    font-weight: 700;
}

#agr_wrap abbr {
    text-decoration: none;
}

.agr_fade-out {
    -webkit-animation: 0.25s ease 0s 1 normal none running fadeOut;
            animation: 0.25s ease 0s 1 normal none running fadeOut;
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes ripple {
    0% {
        opacity: 0;
        width: 0px;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        padding-bottom: 130%;
        width: 130%;
    }
}

@keyframes ripple {
    0% {
        opacity: 0;
        width: 0px;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        padding-bottom: 130%;
        width: 130%;
    }
}

#agr_wrap .agr_btn, #agr_wrap .agr_btn--outline {
    background: rgb(0, 116, 204);
    border: none;
    border-radius: 3px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    display: inline-block;
    font-weight: 300;
    line-height: 1.5;
    margin: 0px;
    overflow: hidden;
    padding: 8px 15px 6px;
    position: relative;
    text-align: center;
    text-decoration: none;
    -webkit-transition: background 0.25s ease 0s, border 0.25s ease 0s;
    transition: background 0.25s ease 0s, border 0.25s ease 0s;
    vertical-align: middle;
    z-index: 1;
}

#agr_wrap .agr_btn::before, #agr_wrap .agr_btn::after, #agr_wrap .agr_btn--outline::before, #agr_wrap .agr_btn--outline::after {
    background: rgba(255, 255, 255, 0.15);
    border-radius: 50%;
    content: "";
    opacity: 0;
    padding: 0px;
    position: absolute;
    -webkit-transition: all 0.5s ease 0s, opacity 0.25s ease 0s, background 0s linear 0s;
    transition: all 0.5s ease 0s, opacity 0.25s ease 0s, background 0s linear 0s;
    width: 0px;
    z-index: -1;
}

#agr_wrap .agr_btn::after, #agr_wrap .agr_btn--outline::after {
    background-color: rgba(255, 255, 255, 0.25);
}

.touch #agr_wrap .agr_btn::before, .touch #agr_wrap .agr_btn::after, .touch #agr_wrap .agr_btn--outline::before, .touch #agr_wrap .agr_btn--outline::after {
    content: none;
    display: none;
}

#agr_wrap .agr_btn:hover, #agr_wrap .agr_btn.selected, #agr_wrap .agr_btn--outline:hover, #agr_wrap .selected.agr_btn--outline {
    color: rgb(255, 255, 255);
    text-decoration: none;
}

#agr_wrap .agr_btn:hover::before, #agr_wrap .agr_btn.selected::before, #agr_wrap .agr_btn--outline:hover::before, #agr_wrap .selected.agr_btn--outline::before {
    opacity: 1;
    padding-bottom: 130%;
    width: 130%;
}

#agr_wrap .agr_btn:focus:not(:active), #agr_wrap .agr_btn--outline:focus:not(:active) {
    text-decoration: none;
}

#agr_wrap .agr_btn:focus:not(:active):not(.btn-clicked), #agr_wrap .agr_btn--outline:focus:not(:active):not(.btn-clicked) {
    outline: rgb(0, 0, 0) dotted 2px;
}

#agr_wrap .agr_btn:focus:not(:active)::after, #agr_wrap .agr_btn--outline:focus:not(:active)::after {
    -webkit-animation: 0.5s ease 0s 1 normal none running ripple;
            animation: 0.5s ease 0s 1 normal none running ripple;
}

#agr_wrap .agr_btn[disabled], #agr_wrap .agr_btn.disabled, #agr_wrap .agr_btn--outline[disabled], #agr_wrap .disabled.agr_btn--outline {
    background: rgb(198, 211, 215);
    color: rgb(118, 131, 143);
    cursor: default;
}

#agr_wrap .agr_btn[disabled]:hover, #agr_wrap .agr_btn.disabled:hover, #agr_wrap .agr_btn--outline[disabled]:hover, #agr_wrap .disabled.agr_btn--outline:hover {
    background: rgb(198, 211, 215);
}

#agr_wrap .agr_btn[disabled]::before, #agr_wrap .agr_btn[disabled]::after, #agr_wrap .agr_btn.disabled::before, #agr_wrap .agr_btn.disabled::after, #agr_wrap .agr_btn--outline[disabled]::before, #agr_wrap .agr_btn--outline[disabled]::after, #agr_wrap .disabled.agr_btn--outline::before, #agr_wrap .disabled.agr_btn--outline::after {
    display: none;
}

#agr_wrap .agr_btn[disabled] .icn, #agr_wrap .agr_btn.disabled .icn, #agr_wrap .agr_btn--outline[disabled] .icn, #agr_wrap .disabled.agr_btn--outline .icn {
    fill: rgb(118, 131, 143);
}

#agr_wrap .agr_btn--outline {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(0, 116, 204);
    color: rgb(0, 116, 204);
    padding: 7px 14px 5px;
}

#agr_wrap .agr_btn--outline .sid_icn {
    fill: rgb(0, 116, 204);
}

#agr_wrap .agr_btn--outline:hover, #agr_wrap .agr_btn--outline.selected {
    border-color: rgb(38, 132, 202);
    color: rgb(255, 255, 255);
}

#agr_wrap .agr_btn--outline:hover::before, #agr_wrap .agr_btn--outline.selected::before {
    background: rgb(38, 132, 202);
}

#agr_wrap .agr_btn--outline:hover .icn, #agr_wrap .agr_btn--outline.selected .icn {
    fill: rgb(255, 255, 255);
}

#agr_wrap .agr_btn--outline:disabled {
    background: none;
    border-color: rgb(228, 234, 236);
    color: rgb(174, 182, 189);
}

#agr_wrap .agr_btn--outline:disabled:hover {
    background: none;
}

#agr_wrap .agr_btn--outline:disabled:hover::before, #agr_wrap .agr_btn--outline:disabled:hover::after {
    display: none;
}

#agr_wrap .agr_btn--outline.sid_btn--negative {
    border-color: rgb(233, 89, 91);
    color: rgb(233, 89, 91);
}

#agr_wrap .agr_btn--outline.sid_btn--negative .icn {
    fill: rgb(233, 89, 91);
}

#agr_wrap .agr_btn--outline.sid_btn--negative:hover, #agr_wrap .agr_btn--outline.sid_btn--negative.selected {
    border-color: rgb(236, 112, 113);
    color: rgb(255, 255, 255);
}

#agr_wrap .agr_btn--outline.sid_btn--negative:hover::before, #agr_wrap .agr_btn--outline.sid_btn--negative.selected::before {
    background: rgb(236, 112, 113);
}

#agr_wrap .agr_btn--outline.sid_btn--negative:hover .icn, #agr_wrap .agr_btn--outline.sid_btn--negative.selected .icn {
    fill: rgb(255, 255, 255);
}

#agr_wrap .agr_btn--link {
    color: rgb(0, 116, 204);
    text-decoration: underline;
}

#agr_wrap .agr_btn--link:hover {
    color: rgb(58, 145, 228);
}

@-webkit-keyframes ripple-safari {
    0% {
        opacity: 0;
        width: 0px;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        padding-bottom: 300%;
        width: 300%;
    }
}

@keyframes ripple-safari {
    0% {
        opacity: 0;
        width: 0px;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        padding-bottom: 300%;
        width: 300%;
    }
}

#agr_wrap .agr_btn:not(:root:root)::before, #agr_wrap .agr_btn:not(:root:root)::after, #agr_wrap .agr_btn--outline:not(:root:root)::before, #agr_wrap .agr_btn--outline:not(:root:root)::after {
    display: table;
}

#agr_wrap .agr_btn:not(:root:root):hover::before, #agr_wrap .agr_btn:not(:root:root).selected::before, #agr_wrap .agr_btn--outline:not(:root:root):hover::before, #agr_wrap .agr_btn--outline:not(:root:root).selected::before {
    padding-bottom: 300%;
    width: 300%;
}

#agr_wrap .agr_btn:not(:root:root):focus:not(:active)::after, #agr_wrap .agr_btn--outline:not(:root:root):focus:not(:active)::after {
    -webkit-animation: 0.5s ease 0s 1 normal none running ripple-safari;
            animation: 0.5s ease 0s 1 normal none running ripple-safari;
}

#agr_wrap .agr_cbox + .agr_cbox {
    margin-top: 10px;
}

#agr_wrap .agr_cbox input[type="checkbox"] {
    opacity: 0;
    position: absolute;
}

#agr_wrap .agr_cbox input[type="checkbox"]:focus + label {
    outline: rgb(0, 116, 204) dotted 2px;
}

#agr_wrap .agr_cbox input[type="checkbox"]:checked + label::before, #agr_wrap .agr_cbox input[type="checkbox"]:checked + label::after {
    border-color: #0a4edf;
}

#agr_wrap .agr_cbox input[type="checkbox"]:checked + label::before {
    background: #0a4edf;
}

#agr_wrap .agr_cbox input[type="checkbox"]:checked + label::after {
    left: 20px;
}

#agr_wrap .agr_cbox label {
    display: inline-block;
    font-weight: 400;
    line-height: 20px;
    padding-left: 50px;
    position: relative;
}

#agr_wrap .agr_cbox label::before, #agr_wrap .agr_cbox label::after {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(198, 211, 215);
    content: "";
    cursor: pointer;
    height: 20px;
}

#agr_wrap .agr_cbox label::before {
    border-radius: 100em;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 40px;
}

#agr_wrap .agr_cbox label::after {
    border-radius: 50%;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 20px;
}

#agr_wrap .agr_title {
    font-weight: 300;
}

#agr_wrap .agr_title + .agr_body {
    margin-top: 20px;
}

#agr_wrap .agr_body {
    border: 1px solid rgb(228, 234, 236);
    padding: 15px;
    margin-bottom: 30px;
    max-height: 40vh;
    overflow: auto;
    text-align: left;
}

#agr_wrap .agr_body:last-child {
    margin-bottom: 0px;
}

#agr_wrap .agr_body:only-child {
    border: none;
    padding: 0px;
}

#agr_wrap .agr_body p:last-child, #agr_wrap .agr_body ol:last-child, #agr_wrap .agr_body ul:last-child {
    margin-bottom: 0px;
}

#agr_wrap .agr_body p:empty, #agr_wrap .agr_body ol:empty, #agr_wrap .agr_body ul:empty {
    display: none;
}

#agr_wrap .agr_body p br:first-child br:only-child, #agr_wrap .agr_body ol br:first-child br:only-child, #agr_wrap .agr_body ul br:first-child br:only-child {
    display: none;
}

#agr_wrap .agr_sub-heading {
    display: block;
    margin-bottom: 10px;
}

#agr_wrap input[type="color"].error, #agr_wrap input[type="date"].error, #agr_wrap input[type="datetime-local"].error, #agr_wrap input[type="datetime"].error, #agr_wrap input[type="email"].error, #agr_wrap input[type="month"].error, #agr_wrap input[type="number"].error, #agr_wrap input[type="password"].error, #agr_wrap input[type="search"].error, #agr_wrap input[type="tel"].error, #agr_wrap input[type="text"].error, #agr_wrap input[type="time"].error, #agr_wrap input[type="url"].error, #agr_wrap input[type="week"].error, #agr_wrap textarea.error {
    border-color: rgb(233, 89, 91);
}

#agr_wrap input[type="checkbox"].error + label::before, #agr_wrap input[type="checkbox"].error + label::after, #agr_wrap input[type="radio"].error + label::before, #agr_wrap input[type="radio"].error + label::after {
    border-color: rgb(233, 89, 91);
}

#agr_wrap .agr_explicit-consent {
    background: rgb(250, 252, 252);
    margin: 15px 0px 0px;
    padding: 15px;
}

#agr_wrap .agr_explicit-consent :last-child {
    margin-bottom: 0px;
}

#agr_wrap .agr_explicit-consent input[type="radio"]:checked ~ .agr_explicit-consent__form {
    display: flex;
}

#agr_wrap .agr_explicit-consent .agr_btn--link {
    margin-top: 10px;
}

#agr_wrap .agr_explicit-consent__form {
    display: none;
    margin-top: 5px;
}

#agr_wrap .agr_explicit-consent__form .agr_select {
    margin: 0px 10px 0px 0px;
}

#agr_wrap .agr_explicit-consent__form input {
    flex-shrink: 1;
}

#agr_wrap .agr_explicit-consent__form .agr_btn--outline {
    flex-shrink: 0;
    margin-left: 10px;
    white-space: nowrap;
}

#agr_wrap .agr_fields {
    margin-bottom: 30px;
}

#agr_wrap .agr_fields > li + li {
    margin-top: 10px;
}

#agr_wrap input[type="color"], #agr_wrap input[type="date"], #agr_wrap input[type="datetime-local"], #agr_wrap input[type="datetime"], #agr_wrap input[type="email"], #agr_wrap input[type="month"], #agr_wrap input[type="number"], #agr_wrap input[type="password"], #agr_wrap input[type="search"], #agr_wrap input[type="tel"], #agr_wrap input[type="text"], #agr_wrap input[type="time"], #agr_wrap input[type="url"], #agr_wrap input[type="week"], #agr_wrap textarea {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(228, 234, 236);
    border-radius: 3px;
}

#agr_wrap input[type="color"], #agr_wrap input[type="date"], #agr_wrap input[type="datetime-local"], #agr_wrap input[type="datetime"], #agr_wrap input[type="email"], #agr_wrap input[type="month"], #agr_wrap input[type="number"], #agr_wrap input[type="password"], #agr_wrap input[type="search"], #agr_wrap input[type="tel"], #agr_wrap input[type="text"], #agr_wrap input[type="time"], #agr_wrap input[type="url"], #agr_wrap input[type="week"], #agr_wrap textarea {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    height: 35px;
    color: rgb(82, 96, 105);
    padding-left: 10px;
    -webkit-transition: border-color 0.25s ease 0s;
    transition: border-color 0.25s ease 0s;
    width: 100%;
}

#agr_wrap input[type="color"]:focus, #agr_wrap input[type="date"]:focus, #agr_wrap input[type="datetime-local"]:focus, #agr_wrap input[type="datetime"]:focus, #agr_wrap input[type="email"]:focus, #agr_wrap input[type="month"]:focus, #agr_wrap input[type="number"]:focus, #agr_wrap input[type="password"]:focus, #agr_wrap input[type="search"]:focus, #agr_wrap input[type="tel"]:focus, #agr_wrap input[type="text"]:focus, #agr_wrap input[type="time"]:focus, #agr_wrap input[type="url"]:focus, #agr_wrap input[type="week"]:focus, #agr_wrap textarea:focus {
    border: 2px solid rgb(0, 116, 204);
}

#agr_wrap input[type="color"]::-webkit-input-placeholder, #agr_wrap input[type="date"]::-webkit-input-placeholder, #agr_wrap input[type="datetime-local"]::-webkit-input-placeholder, #agr_wrap input[type="datetime"]::-webkit-input-placeholder, #agr_wrap input[type="email"]::-webkit-input-placeholder, #agr_wrap input[type="month"]::-webkit-input-placeholder, #agr_wrap input[type="number"]::-webkit-input-placeholder, #agr_wrap input[type="password"]::-webkit-input-placeholder, #agr_wrap input[type="search"]::-webkit-input-placeholder, #agr_wrap input[type="tel"]::-webkit-input-placeholder, #agr_wrap input[type="text"]::-webkit-input-placeholder, #agr_wrap input[type="time"]::-webkit-input-placeholder, #agr_wrap input[type="url"]::-webkit-input-placeholder, #agr_wrap input[type="week"]::-webkit-input-placeholder, #agr_wrap textarea::-webkit-input-placeholder {
    color: rgb(174, 182, 189);
}

#agr_wrap input[type="color"]:-ms-input-placeholder, #agr_wrap input[type="date"]:-ms-input-placeholder, #agr_wrap input[type="datetime-local"]:-ms-input-placeholder, #agr_wrap input[type="datetime"]:-ms-input-placeholder, #agr_wrap input[type="email"]:-ms-input-placeholder, #agr_wrap input[type="month"]:-ms-input-placeholder, #agr_wrap input[type="number"]:-ms-input-placeholder, #agr_wrap input[type="password"]:-ms-input-placeholder, #agr_wrap input[type="search"]:-ms-input-placeholder, #agr_wrap input[type="tel"]:-ms-input-placeholder, #agr_wrap input[type="text"]:-ms-input-placeholder, #agr_wrap input[type="time"]:-ms-input-placeholder, #agr_wrap input[type="url"]:-ms-input-placeholder, #agr_wrap input[type="week"]:-ms-input-placeholder, #agr_wrap textarea:-ms-input-placeholder {
    color: rgb(174, 182, 189);
}

#agr_wrap input[type="color"]::-ms-input-placeholder, #agr_wrap input[type="date"]::-ms-input-placeholder, #agr_wrap input[type="datetime-local"]::-ms-input-placeholder, #agr_wrap input[type="datetime"]::-ms-input-placeholder, #agr_wrap input[type="email"]::-ms-input-placeholder, #agr_wrap input[type="month"]::-ms-input-placeholder, #agr_wrap input[type="number"]::-ms-input-placeholder, #agr_wrap input[type="password"]::-ms-input-placeholder, #agr_wrap input[type="search"]::-ms-input-placeholder, #agr_wrap input[type="tel"]::-ms-input-placeholder, #agr_wrap input[type="text"]::-ms-input-placeholder, #agr_wrap input[type="time"]::-ms-input-placeholder, #agr_wrap input[type="url"]::-ms-input-placeholder, #agr_wrap input[type="week"]::-ms-input-placeholder, #agr_wrap textarea::-ms-input-placeholder {
    color: rgb(174, 182, 189);
}

#agr_wrap input[type="color"]::placeholder, #agr_wrap input[type="date"]::placeholder, #agr_wrap input[type="datetime-local"]::placeholder, #agr_wrap input[type="datetime"]::placeholder, #agr_wrap input[type="email"]::placeholder, #agr_wrap input[type="month"]::placeholder, #agr_wrap input[type="number"]::placeholder, #agr_wrap input[type="password"]::placeholder, #agr_wrap input[type="search"]::placeholder, #agr_wrap input[type="tel"]::placeholder, #agr_wrap input[type="text"]::placeholder, #agr_wrap input[type="time"]::placeholder, #agr_wrap input[type="url"]::placeholder, #agr_wrap input[type="week"]::placeholder, #agr_wrap textarea::placeholder {
    color: rgb(174, 182, 189);
}

.touch #agr_wrap input[type="color"], .touch #agr_wrap input[type="date"], .touch #agr_wrap input[type="datetime-local"], .touch #agr_wrap input[type="datetime"], .touch #agr_wrap input[type="email"], .touch #agr_wrap input[type="month"], .touch #agr_wrap input[type="number"], .touch #agr_wrap input[type="password"], .touch #agr_wrap input[type="search"], .touch #agr_wrap input[type="tel"], .touch #agr_wrap input[type="text"], .touch #agr_wrap input[type="time"], .touch #agr_wrap input[type="url"], .touch #agr_wrap input[type="week"], .touch #agr_wrap textarea {
    font-size: 16px;
}

#agr_wrap textarea {
    height: 3.92857em;
    line-height: 1.5;
    max-height: 50vh;
    padding: 7px 10px;
}

#agr_wrap input.agr_initials {
    padding-left: 0px;
    text-align: center;
    width: 10ex;
}

#agr_wrap label {
    display: table;
    font-weight: 500;
    margin-bottom: 5px;
}

.has-agr_popup::after {
    background: rgba(0, 0, 0, 0.25);
    content: "";
    height: 100%;
    left: 0px;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 999999;
}

.agr_popup-wrap {
    -webkit-animation: 0.25s ease 0s 1 normal none running fadeIn;
            animation: 0.25s ease 0s 1 normal none running fadeIn;
    inset: 0px;
    position: fixed;
    text-align: center;
    z-index: 1000000;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

#agr_wrap .agr_popup {
    -webkit-animation: 0.5s ease 0s 1 normal none running popUp;
            animation: 0.5s ease 0s 1 normal none running popUp;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(228, 234, 236);
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    display: inline-block;
    max-height: 95vh;
    max-width: 600px;
    overflow: auto;
    padding: 15px;
    position: relative;
    text-align: left;
    vertical-align: middle;
    width: 95%;
    z-index: 999999;
}

@media only screen and (min-width: 54.85714rem) {
    #agr_wrap .agr_popup {
        padding: 30px;
    }
}

@-webkit-keyframes popUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
}

@keyframes popUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
}

#agr_wrap .agr_popup__close {
    color: rgb(0, 116, 204);
    font-size: 30px;
    position: absolute;
    right: 10px;
    top: 4px;
}

#agr_wrap .agr_popup__close + .agr_form {
    margin-top: 20px;
}

#agr_wrap .agr_radio + .agr_radio {
    margin-top: 10px;
}

#agr_wrap .agr_radio input[type="radio"] {
    opacity: 0;
    position: absolute;
}

#agr_wrap .agr_radio input[type="radio"]:focus + label {
    outline: rgb(0, 116, 204) dotted 2px;
}

#agr_wrap .agr_radio input[type="radio"]:checked + label::before {
    border: 6px solid rgb(0, 116, 204);
}

#agr_wrap .agr_radio label:not([class]) {
    display: inline-block;
    font-weight: 400;
    line-height: 22px;
    padding-left: 30px;
    position: relative;
}

#agr_wrap .agr_radio label:not([class])::before {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(198, 211, 215);
    border-radius: 50%;
    content: "";
    cursor: pointer;
    height: 20px;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 20px;
}

#agr_wrap .agr_select select {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(228, 234, 236);
    border-radius: 3px;
    color: rgb(82, 96, 105);
    font-size: inherit;
    height: 100%;
    line-height: 1.5;
    padding: 6px 27px 6px 10px;
    width: 100%;
}

#agr_wrap .agr_select select:focus {
    outline: rgb(0, 116, 204) solid 2px;
}

#agr_wrap .agr_select select:disabled {
    background: rgb(243, 247, 249);
    color: rgb(82, 96, 105);
}

#agr_wrap .agr_select {
    display: inline-block;
    line-height: 1.5;
    position: relative;
    vertical-align: top;
}

#agr_wrap .agr_select::after {
    position: absolute;
    right: 10px;
}

#agr_wrap .agr_select select {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    margin: 0px;
}

#agr_wrap .agr_select select:invalid {
    color: rgb(174, 182, 189);
}

@media only screen and (min-width: 54.85714rem) {
    #agr_wrap .agr_select + .agr_btn--outline {
        margin-left: 10px;
    }
}

#agr_wrap .sid_select--full {
    margin-bottom: 20px;
    width: 100%;
}

#agr_wrap .sid_select--full + textarea {
    margin-bottom: 20px;
}

#agr_wrap .sid_select--alt select {
    border: none;
    color: rgb(118, 131, 143);
    padding: 0px 12px 0px 0px;
}

#agr_wrap .sid_select--alt::after {
    right: 0px;
}
.card-box .side-1, .card-box .side-2 {
    list-style: none;
    text-align: left;
    width: 50%;
    margin: 0;
}
.clearfix
{
    display: block;
    clear: both;
}
.card-box .side-1 {
    float: left;
    text-align: left;
    font-size: 17px;
    color: #333333;
}

.card-box .side-2 {
    float: right;
    text-align: right;
    font-size: 17px;
    color: #393939;
    font-weight: 500;
}

.card-box .side-1 li,.card-box .side-2 li {
    margin-bottom: 15px;
}

.card-box h2 {
    margin-bottom: 20px;
    color:#093dad;
    font-weight: 500;
}
.card-box .side-2 li.color-1
{
    color: #FA6400;
}
.card-box .side-2 li.color-2
{
    color: #109E7E;
}
.card-box .side-2 li.color-3
{
    color: #F44336;
}
.location-pin {
    text-align: left;
    margin-top: 10px;
}
.location-pin img {
    float: left;
    margin-right: 10px;
}

.location-pin p {
    font-size: 17px;
    position: relative;
    bottom: 4px;
}
.devide {
    border-bottom: dashed 1px #979797;
        margin-top: 20px;
    margin-bottom: 25px;
}
/****************************/
#submit{background: #5469d4;
font-family: Arial, sans-serif;
color: #ffffff;
border-radius: 0 0 4px 4px;
border: 0;
padding: 12px 16px;
font-size: 16px;
font-weight: 600;
cursor: pointer;
display: block;
-webkit-transition: all 0.2s ease;
transition: all 0.2s ease;
box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
width: 100%;
}
.alert-error, .card-error {
    padding: 20px;
    background-color: #f44336;
    color: white;
  }
  .alert-success {
    padding: 20px;
    background-color: #04AA6D;
    color: white;
  }
  .alert-info {
    padding: 20px;
    background-color: #2769f5;
    color: white;
  }
  
  .back{
      cursor: pointer;
  }
  /*************************/
  /*.container {
    position: relative;
  }
  
  .page {
    position: absolute;
    left: 15px;
    right: 15px;
  }
  
  .page-enter {
    opacity: 0;
    transform: scale(1.1);
  }
  
  .page-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }
  
  .page-exit {
    opacity: 1;
    transform: scale(1);
  }
  
  .page-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
  
*/

.alert-error, .card-error {
    background: none;
    color: #f44336;
    padding: 0;
    margin-left: 8px;
    margin-top: 8px;
}
.alert-success {
    padding: 10px 20px;
    background-color: #05c57f;
    color: white;
}

.alert-success label {
    margin-bottom: 0;
}
@media only screen and (max-width:675px)
{

.form__fields > li.field--half
{
    width:100%;
    margin-left: 0;
    padding-left: 0 !important;
}

}
.logout {
    float: right;
    margin-top: 14px;
    margin-right: 10px;
    text-decoration: none;
}
.brand,
.brand__logo {
    display: inline-block;
}

.brand__logo {
    max-height: 50px;
    width: auto
}

@media only screen and (max-width: 47.9375em) {
    .brand__logo {
        max-height: 35px
    }
}

/* ===== 17-06-2021 ===== */
#header .brand img {
    width: 130px;
    height: auto;
    max-height: 100%;
}

/* ======= 21 - 06 - 2021 ====== */

.table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent
}

.exam-dd .table td,
.exam-dd .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    font-size: 15px;
}

.exam-dd .table thead th {
    vertical-align: bottom;
    /*    border-bottom: 2px solid #dee2e6*/
}

.table tbody + tbody {
    border-top: 2px solid #dee2e6
}

.table .table {
    background-color: #fff
}

.table-sm td,
.table-sm th {
    padding: .3rem
}

.table-bordered {
    border: 1px solid #dee2e6
}

.table-bordered td,
.table-bordered th {
    border: 1px solid #dee2e6
}

.table-bordered thead td,
.table-bordered thead th {
    border-bottom-width: 2px
}

.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
    border: 0
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05)
}

.table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, .075)
}

.table-primary,
.table-primary > td,
.table-primary > th {
    background-color: #b8daff
}

.table-hover .table-primary:hover {
    background-color: #9fcdff
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
    background-color: #9fcdff
}

.table-secondary,
.table-secondary > td,
.table-secondary > th {
    background-color: #d6d8db
}

.table-hover .table-secondary:hover {
    background-color: #c8cbcf
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
    background-color: #c8cbcf
}

.table-success,
.table-success > td,
.table-success > th {
    background-color: #c3e6cb
}

.table-hover .table-success:hover {
    background-color: #b1dfbb
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
    background-color: #b1dfbb
}

.table-info,
.table-info > td,
.table-info > th {
    background-color: #bee5eb
}

.table-hover .table-info:hover {
    background-color: #abdde5
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
    background-color: #abdde5
}

.table-warning,
.table-warning > td,
.table-warning > th {
    background-color: #ffeeba
}

.table-hover .table-warning:hover {
    background-color: #ffe8a1
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
    background-color: #ffe8a1
}

.table-danger,
.table-danger > td,
.table-danger > th {
    background-color: #f5c6cb
}

.table-hover .table-danger:hover {
    background-color: #f1b0b7
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
    background-color: #f1b0b7
}

.table-light,
.table-light > td,
.table-light > th {
    background-color: #fdfdfe
}

.table-hover .table-light:hover {
    background-color: #ececf6
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
    background-color: #ececf6
}

.table-dark,
.table-dark > td,
.table-dark > th {
    background-color: #c6c8ca
}

.table-hover .table-dark:hover {
    background-color: #b9bbbe
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
    background-color: #b9bbbe
}

.table-active,
.table-active > td,
.table-active > th {
    background-color: rgba(0, 0, 0, .075)
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, .075)
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, .075)
}

.table .thead-dark th {
    color: #fff;
    background-color: #212529;
    border-color: #32383e
}

.table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6
}


@media (max-width: 1199.98px) {

    .table-responsive-xl > .table-bordered {
        border: 0
    }


.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar
}

.table-responsive > .table-bordered {
    border: 0
}
}


.exam-dd .table {
    background-color: #ffffff;
    color: #444;
    font-size: 85%;
    margin-bottom: 0;
}

.exam-dd .table thead {
    background-color: #ffffff;
    color: #444;
}

.exam-dd .table thead th {
    border-bottom: 0;
    border: none;
    padding: 20px 25px;
    font-weight: 600;
    border-bottom: 1px solid #dee2e6;
}

.exam-dd .table td,
.exam-dd .table th {
    border-top: none;
    padding: 15px 25px;
    vertical-align: middle;
}

.exam-dd .table tbody tr:nth-child(even) {
    background: #f7f7f7;
}


/* .exam-dd a {
    color: #740136;
    text-decoration: underline;
} */

.exam-dd .text-red {
    color: red;
}


.exam-dd .text-green {
    color: #28a745;
}

.exam-dd .text-orange {
    color: orange;
}
.exam-dd .st-check
 {
    font-size: 15px;
    color: #730036;
}

.left {
    float: left;
}

.right {
    float: right;
}
@media (max-width: 500px)
{
    .left, .right
    {
        float: none;;
    }
}

.react-datepicker {
    position: relative;
}
.react-datepicker__day:hover, .react-datepicker__day.react-datepicker__day--keyboard-selected, .react-datepicker__day--selected
 {
    background: #2769f5;
    color: #fff;
}
.mt-5 {
    margin-top: 20px;
}

.mb-5 {
    margin-bottom: 20px;
}
.mt-7 {
    margin-top: 30px;
}

/* ======== 23-06-2021 ======= */
.col-5
{
    width: 50%;
    float: left;
    padding: 0 10px;
}

@media (max-width:650px) {

    .col-5
{
    width: 100%;
    float: none;
    padding: 0 ;
    margin-bottom: 30px;
}

    
    
}

.capitalize{
    text-transform: capitalize;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.red-border {
    width: '100%'
  }
.react-datepicker-ignore-onclickoutside {
    width: 100% !important;
  }
  
.react-datepicker__input-container input {
      width: 100%;
  }

  

  
  
  
  input {
    border-radius: 6px;
    margin-bottom: 6px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    font-size: 16px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }
  
  .result-message {
    line-height: 22px;
    font-size: 16px;
  }
  
  .result-message a {
    color: rgb(89, 111, 214);
    font-weight: 600;
    text-decoration: none;
  }
  
  .hidden {
    display: none;
  }
  
  #card-error {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
    text-align: center;
  }
  
  #card-element {
    border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }
  
  #payment-request-button {
    margin-bottom: 32px;
  }
  
  /* Buttons and links */

  
  button:hover {
    -webkit-filter: contrast(115%);
            filter: contrast(115%);
  }
  
  button:disabled {
    opacity: 0.5;
    cursor: default;
  }
  
  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: "";
  }
  
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  
  @-webkit-keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @media only screen and (max-width: 600px) {
    form {
      width: 80vw;
    }
  }
  
